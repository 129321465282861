import axios from 'axios';
import { storeDataSecure, retrieveDataSecure } from '../utils/offlineStorage';
import { refreshToken } from './auth';


// export const API_URL = "http://13.36.110.2:9000/en";
// export const API_URL = "http://api.sadic-tipsom-test.net:443/en";
export const API_URL = "https://api.sadic-tipsom-test.net/en";
// export const API_URL = "http://tipsomuat.sadc.int:8000/en";
// export const API_URL = "https://tipsomuat.sadc.int/en";

export const GOOGLE_RECAPTCHA_KEY = "6LeGAjMqAAAAAMm5WxFLqGcd9obS6VjesaOpvj7H";

const TOKEN_EXPIRATION_MARGIN = 5 * 60 * 1000; // 5 minutes margin
export const TOKEN_EXPIRATION_TIME = 60 * 60 * 1000; // Token expires in 1 hour

async function tokenGet() {
  const expirationTime = await retrieveDataSecure('jwt_token_expiration');

  // If expirationTime exists, parse it and check if the token is still valid
  if (expirationTime) {
    const isTokenExpired = Date.now() > parseInt(expirationTime) - TOKEN_EXPIRATION_MARGIN;

    if (isTokenExpired) {
      // Token is expired or about to expire, refresh it
      await refreshToken();
    }
  } else {
    // If there's no expiration time, assume the token is invalid and refresh it
    await refreshToken();
  }
  
  const token = await retrieveDataSecure('jwt_token');
  return token;
}

export const fetchInterviewerDetails = async (victimId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet()
    const response = await axios.get(`${API_URL}/api/interviewers/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response errors:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const updateInterviewerDetails = async (profile, victimId, interviewerId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/interviewers/${interviewerId}/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const addInterviewer = async (profile) => {
  try {
    const response = await axios.post(`${API_URL}/api/interviewers/`, profile);
    return response;
  } catch (error) {
    console.log("error", error);
    return error.response;
  }
}


export const resetPassword = async (profile) => {
  try {
    const response = await axios.post(`${API_URL}/api/password-reset/`, profile);
    return response;
  } catch (error) {
    console.log("error", error);
    return error.response;
  }
}

export const resetPasswordConfirm = async (profile) => {
  try {
    const response = await axios.post(`${API_URL}/api/password-reset-confirm/`, profile);
    return response;
  } catch (error) {
    console.log("error", error);
    return error.response;
  }
}

export const getVictims = async () => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      const response = await axios.get(`${API_URL}/api/victims/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addVictim = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/victims/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}
export const updateVictim = async (profile, victimId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/victims/${victimId}/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchVictimDetails = async (victimId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/victims/${victimId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching victim details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};

export const getTipCases = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/cases/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addTipCase = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/cases/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateTipCase = async (data, tId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/cases/${tId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    throw error;
  }
}

export const fetchTipCaseDetails = async (cId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/cases/${cId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching transit details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};

export const getSuspects = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/suspects/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSuspect = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/suspects/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSuspectDetails = async (victimId, suspectId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/suspects/${victimId}/${suspectId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching suspect details:', error);
    // throw error;

    // Handle error (e.g., show an error message)
  }
};

export const updateSuspect = async (data, victimId, sId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/suspects/${victimId}/${sId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } else {
      throw new Error("Authentication token not available.");
    }
  } catch (error) {
    throw error;
  }
};

export const getExploitations = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/exploitations/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addExploitation = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/exploitations/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateExploitation = async (data, victimId, eId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/exploitations/${victimId}/${eId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } else {
      throw new Error("Authentication token not available.");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchExploitationDetails = async (victimId, suspectId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint

    const response = await axios.get(`${API_URL}/api/exploitations/${victimId}/${suspectId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching exploitation details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};

export const getTransits = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/transits/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addTransit = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/transits/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateTransit = async (data, victimID, tId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/transits/${victimID}/${tId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    throw error;
  }
}

export const fetchTransitDetails = async (victimId, tId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/transits/${victimId}/${tId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching transit details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getProsecutions = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/prosecutions/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addProsecution = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/prosecutions/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateProsecution = async (data, victimId, pId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/prosecutions/${victimId}/${pId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } else {
      throw new Error("Authentication token not available.");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchProsecutionDetails = async (victimId, pId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/prosecutions/${victimId}/${pId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching prosecution details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getArrests = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/arrests/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addArrest = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/arrests/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateArrest = async (data, victimId, aId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/arrests/${victimId}/${aId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } else {
      throw new Error("Authentication token not available.");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchArrestDetails = async (victimId, suspectId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/arrests/${victimId}/${suspectId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching arrest details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getSocios = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/socios/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSocio = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/socios/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}
export const updateSocio = async (data, victimId, sId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/socios/${victimId}/${sId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    throw error;
  }
}
export const fetchSocioDetails = async (victimId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/socios/${victimId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching socio details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};

export const getAssistanceVictims = async () => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      const response = await axios.get(`${API_URL}/api/assistance-victims/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addAssistanceVictim = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/assistance-victims/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}
export const updateAssistanceVictim = async (profile, victimId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/assistance-victims/${victimId}/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchAssistanceVictimDetails = async (victimId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/assistance-victims/${victimId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching victim details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getAssistances = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/assistances/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addAssistance = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/assistances/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateAssistance = async (data, victimId, assistanceId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/assistances/${victimId}/${assistanceId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchAssistanceDetails = async (victimId, assistanceId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const url = `${API_URL}/api/assistances/${victimId}/${assistanceId}/`;
      //console.log(url)
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching arrest details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const fetchAggregates = async () => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/aggregates/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching aggregate details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};

export const getAggregate = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/aggregates/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};


export const addAggregate = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/aggregates/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchAggregateDetails = async (aggId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/aggregates/${aggId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching aggregate details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};

export const updateAggregate = async (aggId, data) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/aggregates/${aggId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching aggregate details:', error);
    throw error;
  }
}


export const searchVictims = async (data) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/victim/search/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }, params: data
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching search results:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
}










export const getSomSingleVictims = async () => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      const response = await axios.get(`${API_URL}/api/som/victims/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });      
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomSingleVictim = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/victims/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}
export const updateSomSingleVictim = async (profile, victimId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/victims/${victimId}/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomSingleVictimDetails = async (victimId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/victims/${victimId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching victim details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};




export const addSomMultiVictim = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/multi_victims/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}


export const getSomMultiVictims = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/som/multi_victims/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //console.log(response.data)
      return response.data;
    } else {
      throw new Error("Authentication token not available.");
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("API response error:", error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("API no response error:", error.request);
      return Promise.reject("The request was made, but no response was received");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("API request setup error:", error.message);
      return Promise.reject(error.message);
    }
  }
};


export const updateSomMultiVictim = async (data, vId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/multi_victims/${vId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } else {
      throw new Error("Authentication token not available.");
    }
  } catch (error) {
    throw error;
  }
};










export const getSomSuspects = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/som/suspects/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomSuspect = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/suspects/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomSuspectDetails = async (victimId, suspectId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/suspects/${victimId}/${suspectId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching suspect details:', error);
    // throw error;

    // Handle error (e.g., show an error message)
  }
};

export const updateSomSuspect = async (data, victimId, sId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/suspects/${victimId}/${sId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    throw error;
  }
}



export const getSomTransits = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/som/transits/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomTransit = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/transits/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateSomTransit = async (data, victimId, tId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/transits/${victimId}/${tId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomTransitDetails = async (victimId, tId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/transits/${victimId}/${tId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching transit details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getSomMultipleVictimsTransits = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/som/multi-victims-transits/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomMultipleVictimsTransit = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/multi-victims-transits/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateSomMultipleVictimsTransit = async (data, victimId, tId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/multi-victims-transits/${victimId}/${tId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomMultipleVictimsTransitDetails = async (victimId, tId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/multi-victims-transits/${victimId}/${tId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching transit details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getSomProsecutions = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/som/prosecutions/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomProsecution = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/prosecutions/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateSomProsecution = async (data, victimId, pId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/prosecutions/${victimId}/${pId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomProsecutionDetails = async (victimId, pId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/prosecutions/${victimId}/${pId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching prosecution details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getSomArrests = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/som/arrests/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomArrest = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/arrests/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateSomArrest = async (data, victimId, aId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/arrests/${victimId}/${aId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } else {
      throw new Error("Authentication token not available.");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSomArrestDetails = async (victimId, suspectId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/arrests/${victimId}/${suspectId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching arrest details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getSomSocios = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/som/socios/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomSocio = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/socios/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}
export const updateSomSocio = async (data, sId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/socios/${sId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomSocioDetails = async (victimId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/socios/${victimId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching socio details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};

export const getSomAssistanceVictims = async () => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      const response = await axios.get(`${API_URL}/api/som/assistance-victims/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomAssistanceVictim = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/assistance-victims/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}
export const updateSomAssistanceVictim = async (profile, victimId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/assistance-victims/${victimId}/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomAssistanceVictimDetails = async (victimId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/assistance-victims/${victimId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching victim details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getSomAssistanceMultiVictims = async () => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      const response = await axios.get(`${API_URL}/api/som/assistance-multi-victims/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomAssistanceMultiVictim = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/assistance-multi-victims/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}
export const updateSomAssistanceMultiVictim = async (profile, victimId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/assistance-multi-victims/${victimId}/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomAssistanceMultiVictimDetails = async (victimId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/assistance-multi-victims/${victimId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching victim details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getSomAssistances = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/som/assistances/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomAssistance = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/assistances/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateSomAssistance = async (data, victimId, assistanceId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/assistances/${victimId}/${assistanceId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomAssistanceDetails = async (victimId, assistanceId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const url = `${API_URL}/api/som/assistances/${victimId}/${assistanceId}/`;
      //console.log(url)
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching arrest details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const getSomMultiVictimsAssistances = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/som/multi-victims-assistances/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomMultiVictimsAssistance = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/multi-victims-assistances/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateSomMultiVictimsAssistance = async (data, victimId, assistanceId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/multi-victims-assistances/${victimId}/${assistanceId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomMultiVictimsAssistanceDetails = async (victimId, assistanceId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const url = `${API_URL}/api/som/multi-victims-assistances/${victimId}/${assistanceId}/`;
      //console.log(url)
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data[0];
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching arrest details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const searchSomVictims = async (data) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/victim/search/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }, params: data
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching search results:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
}


export const getSomCases = async (victimID = null) => {
  try {
    const token = await tokenGet();
    if (token) {
      //console.log(token)
      //console.log(victimID)
      const url = `${API_URL}/api/som/cases/` + (victimID != null ? victimID + "/" : "");
      //console.log(url);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log(response.data)
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('API response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API no response error:', error.request);
      return Promise.reject('The request was made, but no response was received');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('API request setup error:', error.message);
      return Promise.reject(error.message);
    }
  }
};

export const addSomCase = async (profile) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.post(`${API_URL}/api/som/cases/`, profile, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export const updateSomCase = async (data, tId) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.put(`${API_URL}/api/som/cases/${tId}/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    throw error;
  }
}

export const fetchSomCaseDetails = async (cId) => {
  try {
    // Replace 'your_api_endpoint' with the actual API endpoint
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/som/cases/${cId}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error fetching transit details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};


export const fetchDbLookups = async (current_db_epoch = 'null') => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/db_lookups/${current_db_epoch}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error db lookups details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};

export const fetchDashboardStats = async (language) => {
  try {
    const token = await tokenGet();
    if (token) {
      const response = await axios.get(`${API_URL}/api/dashboard/?language=${language}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } else {
      throw new Error('Authentication token not available.');
    }
  } catch (error) {
    console.error('Error db lookups details:', error);
    throw error;

    // Handle error (e.g., show an error message)
  }
};
