import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../MyInputs/MyTextInput";
import { AuthContext } from "../../App";
import MyCard from "../MyInputs/MyCard";
import MyDatePickerInput from "../MyInputs/MyDatePicker";
import MySelectList from "../MyInputs/MySelectList";
import MyCardTitle from "../MyInputs/MyCardTitle";
import { addSomCase, addVictim, fetchInterviewerDetails, fetchSomCaseDetails, updateInterviewerDetails, updateSomCase } from "../../api/api";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Lock, Login } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import MyFormsGridItem from "../MyInputs/MyFormsGridItem";


const MyInterviewerProfileForm = ({ d }) => {
  const formName = "InterviewerProfileForm";
  const { strings, interviewer } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        firstName: "",
        familyName: "",
        position: "",
        organization: "",
        address: "",
        email: "",
        // country: "",
        dataEntryPurpose: "",
        can_switch_profiles: false,
      })

      // d.setProfile({
      //   firstName: interviewer.first_name,
      //   familyName: interviewer.last_name,
      //   position: interviewer.position,
      //   organization: interviewer.organization,
      //   address: interviewer.address,
      //   email: interviewer.email_address,
      //   country: interviewer.country.id,
      //   dataEntryPurpose: interviewer.data_entry_purpose.id,
      //   password: "",
      // })
    }

    d.setStrings(strings.profileForm)
    d.setFormName(formName)
    d.setSetShouldSetCaseId(true);

    d.setIsProfileSet(true);
    d.setGetFormData(() => fetchInterviewerDetails)
    d.setPutFormData(() => updateInterviewerDetails)
    d.setPostFormData(() => null)

  }, []);

  useEffect(() => {
    d.setStrings(strings.profileForm)
  }, [strings.profileForm]);

  if (!d.isProfileSet) return <div></div>


  return (
    <div>
      <MyCard>
        <MyCardTitle title={strings.profileForm.interviewerInformation} />

        <Grid container>
          <MyFormsGridItem><MyTextInput d={{ name: "firstName", ...d }} /></MyFormsGridItem>
          <MyFormsGridItem><MyTextInput d={{ name: "familyName", ...d }} /></MyFormsGridItem>
          <MyFormsGridItem><MyTextInput d={{ name: "position", ...d }} /></MyFormsGridItem>
          <MyFormsGridItem><MyTextInput d={{ name: "organization", ...d }} /></MyFormsGridItem>
          <MyFormsGridItem><MyTextInput d={{ name: "address", ...d }} /></MyFormsGridItem>
          <MyFormsGridItem><MyTextInput d={{ name: "email", ...d }} inputMode="email" /></MyFormsGridItem>
          {/* <MyFormsGridItem><MySelectList d={{ name: "country", ...d, dbLookupName: "countrys" }} /></MyFormsGridItem> */}
          <MyFormsGridItem><MySelectList d={{ name: "dataEntryPurpose", ...d, dbLookupName: "dataEntryPurposes" }} /></MyFormsGridItem>
          {/* {d.profile.can_switch_profiles && <MyFormsGridItem><MySelectList d={{ name: "dataEntryPurpose", ...d, dbLookupName: "dataEntryPurposes" }} /></MyFormsGridItem>} */}
        </Grid>

        {/* <MyTextInput
          d={{ name: "password", ...d }}
          inputMode={showPassword ? 'text' : 'password'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography gutterBottom sx={{ px: 2 }}>* {strings.profileForm.leavePasswordBlank}</Typography> */}
      </MyCard>
      
      {/* <Typography gutterBottom sx={{ color: 'red' }}>* Form submission is yet to be implemented. so data you edit wont be saved</Typography> */}

    </div>
  );
};

export default MyInterviewerProfileForm;
