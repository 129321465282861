import React, { useContext, useEffect, useState } from "react";

import { Tabs, Tab, Box, Button, Container, Typography } from "@mui/material";
import { AddCircle, TaskAlt } from "@mui/icons-material";
import styles from "../components/MyInputs/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../App";
import MyGeneralForm from "../components/MyForms/MyGeneralForm";
import MyVictimProfileForm from "../components/MyForms/MyVictimProfileForm";
import MyTabPanel from "../components/MyInputs/MyTabPanel";
import MyExploitationForm from "../components/MyForms/MyExploitationForm";
import MyTransistRouteForm from "../components/MyForms/MyTransistRouteForm";
import { theme, navigationRoutes } from "../utils/variables";
import MyCaseForm from "../components/MyForms/MyCaseForm";
import MySomSingleVictimProfileForm from "../components/MyForms/SomForms/MySomSingleVictimProfileForm";
import MyConsentForm from "../components/MyConsentForm";
import MySomInvestigationsForm from "../components/MyForms/SomForms/MySomInvestigationsForm";
import MySuspectedTraffickerForm from "../components/MyForms/MySuspectedTraffickerForm";
import MyProsecutionForm from "../components/MyForms/MyProsecutionForm";
import MySnackBar from "../components/MyInputs/MySnackBar";
import MySomMultipleVictimProfileForm from "../components/MyForms/SomForms/MySomMultipleVictimProfileForm";
import MySomVictimSelector from "../components/MyForms/SomForms/MySomVictimSelector";
import MySomCaseForm from "../components/MyForms/SomForms/MySomCaseForm";
import MySomSuspectedSmugglerForm from "../components/MyForms/SomForms/MySomSuspectedSmugglerForm";
import MySomProsecutionForm from "../components/MyForms/SomForms/MySomProsecutionForm";
import MySomSingleVictimTransistRouteForm from "../components/MyForms/SomForms/MySomSingleVictimTransistRouteForm";
import MySomMultipleVictimTransistRouteForm from "../components/MyForms/SomForms/MySomMultipleVictimTransistRouteForm";
import MyMultipleGeneralForms from "../components/MyForms/MyMultipleGeneralForms";
import MySomAssistanceTypesForm from "../components/MyForms/SomForms/MySomAssistanceTypesForm";
import MySomAssistanceSingleVictimProfileForm from "../components/MyForms/SomForms/MySomAssistanceSingleVictimProfileForm";
import MySomAssistanceMultipleVictimProfileForm from "../components/MyForms/SomForms/MySomAssistanceMultipleVictimProfileForm";
import MySomMultiVictimAssistanceTypesForm from "../components/MyForms/SomForms/MySomMultiVictimAssistanceTypesForm";

const SomCasesAddScreen = ({ d }) => {
  const { strings, interviewer } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [formsUploaded, setFormsUploaded] = useState({});
  const [consentGiven, setConsentGiven] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { isUpdateMode, isViewMode, isCreateMode, isSomSingleVictim, victim, caseData } = location.state || {};
  const [isSingleVictim, setIsSingleVictim] = useState(isSomSingleVictim);

  const [tabsVictimId, setTabsVictimId] = useState(victim?.id);
  const [tabsCaseId, setTabsCaseId] = useState(caseData?.id);
  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const [progressBarMessage, setProgressBarMessage] = useState("");
  const [progressBarSeverity, setProgressBarSeverity] = useState("");
  const onDismissProgressBar = () => {
    setProgressBarVisible(false);
  };

  // console.log(location);
  // console.log("isView ",isUpdateMode," isUpdate ", isViewMode);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const goBackHome = () => {
    navigate(navigationRoutes.somCases);
  };

  const goNextTab = (index) => {
    setSelectedTab(index + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const showSnackBar = (message, severity) => {
    setProgressBarMessage(message);
    setProgressBarSeverity(severity);
    setProgressBarVisible(true);
  };

  const data = {
    victimId: tabsVictimId,
    caseId: tabsCaseId,
    victim: victim,
    caseData: caseData,
    isSomForm: true,
    setVictimId: setTabsVictimId,
    setCaseId: setTabsCaseId,
    isUpdateMode: isUpdateMode,
    isViewMode: isViewMode,
    formsUploaded: formsUploaded,
    setFormsUploaded: setFormsUploaded,
    showSnackBar: showSnackBar,
  };

  const [tabTitles] = useState(
    interviewer?.data_entry_purpose?.id == 1
      ? [
          // { key: "case", title: strings.addSom.case },
          { key: "victimProfile", title: strings.addSom.victimProfile },
          { key: "transitRoute", title: strings.addSom.transitRoute },
        ]
      : interviewer?.data_entry_purpose?.id == 2
      ? [
          { key: "case", title: strings.addSom.case },
          { key: "investigations", title: strings.addSom.investigations },
          { key: "suspects", title: strings.addSom.suspects },
          // { key: "victimProfile", title: strings.addSom.victimProfile },
        ]
      : interviewer?.data_entry_purpose?.id == 3
      ? [
          { key: "case", title: strings.addSom.case },
          { key: "prosecutions", title: strings.addSom.prosecutions },
          { key: "suspects", title: strings.addSom.suspects },
          // { key: "victimProfile", title: strings.addSom.victimProfile },
        ]
      : [
          { key: "victimProfile", title: strings.addSom.victimProfile },
          { key: "assistance", title: strings.addSom.assistance },
        ]
  );

  return (
    <Container>
      {isCreateMode && interviewer?.data_entry_purpose?.id == 1 && (
        <MyConsentForm open={!consentGiven} handleOk={() => setConsentGiven(true)} handleCancel={() => goBackHome()} isSomConsent />
      )}
      <MySnackBar message={progressBarMessage} severity={progressBarSeverity} visible={progressBarVisible} onClose={onDismissProgressBar} />
      <Box>
        <Box position="sticky" top={0} style={{ borderBottom: "1px solid #ccc", zIndex: "10" }} bgcolor="background.paper">
          <Tabs value={selectedTab} onChange={handleChange} centered>
            {tabTitles.map((item, index) => (
              // <Tab key={index} label={`${item.title} ${formsUploaded?.[index] ? '✅' : ''}`} />
              <Tab
                key={index}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {item.title}
                    {formsUploaded?.[index] && <TaskAlt sx={{ ml: 1, color: "green" }} />}
                  </Box>
                }
              />
            ))}
          </Tabs>
        </Box>

        {interviewer?.data_entry_purpose?.id == 1 ? (
          <>
            {isSingleVictim === null || isSingleVictim === undefined ? (
              <MySomVictimSelector setIsSingleVictim={setIsSingleVictim} />
            ) : (
              <>
                <MyTabPanel value={selectedTab} index={0}>
                  <MyGeneralForm
                    onNext={() => goNextTab(0)}
                    FormFields={isSingleVictim ? MySomSingleVictimProfileForm : MySomMultipleVictimProfileForm}
                    d={{ ...data, formIndex: 0 }}
                  />
                </MyTabPanel>
                <MyTabPanel value={selectedTab} index={1}>
                  <MyGeneralForm
                    onNext={() => goBackHome()}
                    FormFields={isSingleVictim ? MySomSingleVictimTransistRouteForm : MySomMultipleVictimTransistRouteForm}
                    d={{ ...data, formIndex: 1 }}
                  />
                </MyTabPanel>
              </>
            )}
          </>
        ) : interviewer?.data_entry_purpose?.id == 2 ? (
          <>
            <MyTabPanel value={selectedTab} index={0}>
              <MyGeneralForm onNext={() => goNextTab(0)} FormFields={MySomCaseForm} d={{ ...data, formIndex: 0 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={1}>
              <MyGeneralForm onNext={() => goNextTab(1)} FormFields={MySomInvestigationsForm} d={{ ...data, formIndex: 1 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={2}>
              <MyMultipleGeneralForms
                onNext={() => goBackHome()}
                FormFields={MySomSuspectedSmugglerForm}
                d={{ ...data, formIndex: 2, formIds: caseData?.suspects }}
              />
            </MyTabPanel>
          </>
        ) : interviewer?.data_entry_purpose?.id == 3 ? (
          <>
            <MyTabPanel value={selectedTab} index={0}>
              <MyGeneralForm onNext={() => goNextTab(0)} FormFields={MySomCaseForm} d={{ ...data, formIndex: 0 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={1}>
              <MyGeneralForm onNext={() => goNextTab(1)} FormFields={MySomProsecutionForm} d={{ ...data, formIndex: 1 }} />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={2}>
              <MyMultipleGeneralForms
                onNext={() => goBackHome()}
                FormFields={MySomSuspectedSmugglerForm}
                d={{ ...data, formIndex: 2, formIds: caseData?.suspects }}
              />
            </MyTabPanel>
          </>
        ) : (
          <>
            {isSingleVictim === null || isSingleVictim === undefined ? (
              <MySomVictimSelector setIsSingleVictim={setIsSingleVictim} />
            ) : (
              <>
                <MyTabPanel value={selectedTab} index={0}>
                  <MyGeneralForm
                    onNext={() => goNextTab(0)}
                    FormFields={isSingleVictim ? MySomAssistanceSingleVictimProfileForm : MySomAssistanceMultipleVictimProfileForm}
                    d={{ ...data, formIndex: 0 }}
                  />
                </MyTabPanel>
                <MyTabPanel value={selectedTab} index={1}>
                  <MyGeneralForm onNext={() => goBackHome()} FormFields={isSingleVictim ? MySomAssistanceTypesForm : MySomMultiVictimAssistanceTypesForm} d={{ ...data, formIndex: 1 }} />
                </MyTabPanel>
              </>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default SomCasesAddScreen;
