const en = {
  app: {
    app_language: "Language",
    login: "Login",
    saveAndProceed: "Save and Proceed",
    saveAndClose: "Save and Close",
    cancel: "Cancel",
    view: "view",
    modify: "modify",
    submit: "Submit",
    updateDbLookups: "Update App Data",
    search: "Search",
  },
  appMenu: {
    tip: "Trafficking of Persons",
    som: "Smuggling of Migrants",
    aggregates: "Aggregate TIP data",
    offlineSync: "Offline Sync",
    findHelp: "Find Help",
    settings: "Settings",
    dashboard: "Dashboard",
    search: "Search",
    profile: "Profile",
  },
  dashboard: {
    victims: "Victims",
    suspects: "Suspects",
    prosecutions: "Prosecutions",
    convictions: "Convictions",
    victimDemographics: "Victim demographics",
    exploitationAnalysis: "Exploitation Analysis",
    suspectDemographics: "Suspect demographics",
    suspectProsecutionAndConviction: "Suspect, prosecution and conviction",
    byGender: "By Gender",
    byNationality: "By Nationality",
  },
  authMenu: {
    login: "TIP-SOM",
  },
  tipMenu: {
    victims: "Victims",
    suspects: "Suspects",
    consent: "Consent",
    addVictim: "Victim Details Form",
    viewVictim: "Victim details",
  },
  somMenu: {
    victims: "Victims",
    suspects: "Suspects",
    consent: "Consent",
    addVictim: "Case Details Form",
    viewVictim: "Case details",
  },
  login: {
    title: "Login",
    username: "Username",
    email: "Email Address",
    password: "Password",
    selectOption: "Select option",
    register: "Register",
    forgotPassword: "Forgot Password?",
    registrationSuccess: "Success",
    registrationSuccessDescription: "Account registered successfully \nPlease procceed to login",
    token: "Enter token",
    tokenSentToEmail: "Login token sent to Email \nPlease check your Email",
    tokenInvalid: "Enter a valid token",
    invalidOrExpiredToken: "Token is Invalid or expired",

  },
  forgotPassword: {
    token: "Enter password reset token",
    tokenSentToEmail: "Password reset token sent to Email \nPlease check your Email",
    password: "Enter New Password",
    confirmPassword: "Re-enter New Password",
    requestResetToken: "Request Password reset token",
    verifyToken: "Verify Token",
    resetPassword: "Reset Password",
  },

  logout: {
    logoutTitle: "Log out",
    logoutText: "Are you sure you want to log out?",
    logoutButton: "Log Out",
  },
  victimScreen: {
    victimsTitle: "Victims",
    suspectsTitle: "Suspects",
  },
  victimCard: {
    exploitationForm: "Exploitation Data",
    transitForm: "Transit route Data",
    investigationForm: "Investigation Data",
    suspectForm: "Suspect Data",
    prosecutionForm: "Prosecution Data",
    assistanceForm: "Assistance Data",
    socioForm: "Socio economic Data",
  },
  victimDetails: {
    caseInterview: "Case interview",
  },
  addTip: {
    victimProfile: "Victim Profile",
    exploitation: "Human Trafficking",
    transitRoute: "Transit Route",
    investigations: "Investigations",
    suspects: "Suspected trafficker",
    suspect: "Suspect",
    prosecutions: "Prosecutions",
    assistance: "Assistance Types",
    socio: "Socio-economic before trafficking",
    aggregate: "Aggregate data on Assistance",
    case: "Case data",
    addNewVictim: "Add TIP Victim",
    addNewCase: "Add TIP Case",
  },
  addSom: {
    victimProfile: "Victim Profile",
    transitRoute: "Transit Route",
    investigations: "Investigations",
    suspects: "Suspected smuggler",
    prosecutions: "Prosecutions",
    assistance: "Assistance Types",
    case: "Case data",
    socio: "Socio-economic before trafficking",
    aggregate: "Aggregate data on Assistance",
    addNewVictim: "Add SOM Victim",
    addNewCase: "Add SOM Case",
  },
  consent: {
    shareData:
      "Has the victim been informed that (name of organization/department) reserves the right to share her/ his individual case data for assistance purposes and only with government departments and partnering organizations involved in direct assistance?",
    limitedDisclosure:
      "Has the victim further been informed that (name of organization/department) reserves the right to make a limited disclosure of non-personal data based on the information collected at the interview to law enforcement for the purpose of rescuing other victims that remain under the control of traffickers or preventing other potential victims from being trafficked?",
    anonymousDataUse:
      "Has the victim further been informed that (name of organization/department) reserves the right to use (only anonymous, aggregate) data for research purpose?",
    rightToRefuse: "Victim has the right to refuse to or abstain from answering by replying Not applicable (NA) or Don’t know (NK)",
    minorOrImpairment: "Is the victim a minor or an individual with physical or cognitive impairments?",
    dataCollectorNoteTitle: "NOTE TO DATA COLLECTOR",
    dataCollectorNote1:
      "1. Smuggled Migrant data involve multiple victims compared to Trafficked victims, thus data entry enables collective data (both Single and Multiple SOM victim data entry",
    dataCollectorNote2: "2. Data will be aggregated in 10 year sets ",
  },
  profileForm: {
    firstName: "First Name",
    familyName: "Family Name",
    position: "Position",
    organization: "Authority/Organization",
    address: "Physical Address",
    email: "Email Address",
    country: "Country",
    dataEntryPurpose: "Data Entry Purpose",
    password: "Password",
    confirmPassword: "Confirm Password",
    leavePasswordBlank: "Leave blank to retain current password",
    interviewerInformation: "Interviewer Information",
    interviewerRegistration: "Interviewer Registration",
  },

  decisionPopup: {
    singleVictim: "Single Victim",
    multipleVictim: "Multiple Victims",
  },
  victim: {
    initials: "Initials",
    email: "Email",
    age: "Age",
    dob: "Date of birth",
    gender: "Sex",
    race: "Race",
    religions: "Religion",
    citizenship: "Citizenship",
    dualCitizenship: "Country of dual citizenship",
    languages: "Languages",
    idType: "ID Type",
    idNumber: "ID Number",
    address: "Address",
    lastPlaceOfResidence: "Last place of residence",
    countryOfBirth: "Country of birth",
    placeOfBirth: "Place of birth",
    interviewDate: "Interview date",
    interviewerCountry: "Interview country",
    interviewerLocation: "Interview location",
    additionalRemarks: "Additional Remarks",
    dateStart: "Interview Date start",
    dateEnd: "Interview Date end",

    bioData: "Bio-Data",
    identifierData: "Identifier Data",
    interviewData: "Interview Data",
    searchVictims: "Search Tip Victims",
    searchSomVictims: "Search Som Victims",
    victimSelectorTitle: "Would you like to add a Single victim or Multiple victims?",
  },
  victimBulk: {
    numberOfVictims: "How many victims are there?",
    genders: "What is their sex distribution?",
    ages: "What is the age distribution of the victims?",
    races: "What races are the victims?",
    religions: "Religion",
    languages: "What languages do they speak?",
    countries: "Suspected country(ies) of origin",
    interviewDate: "Interview date",
    interviewerCountry: "Interview Country",
    interviewerLocation: "Interview location",
    additionalRemarks: "Additional Remarks",
  },
  caseData: {
    dateOfArrest: "Date of arrest",
    roleInTrafficking: "Role in trafficking",
    countryFrom: "Country from",
    placeFrom: "Place from",
    countryTo: "Country to",
    placeTo: "Place to",
    caseNumber: "Case number",
    additionalRemarks: "Additional Remarks",
  },
  exploitation: {
    subjectToExploitation: "Has the victim been subject to exploitation",
    intentToExploit: "If NO, was there an intent to exploit?",
    exploitationLength: "If YES, For how long did the victim experience exploitation?:",
    exploitationAge: "If YES, what was the age(s) of the victim during the period of exploitation?:",
    paidDebt: "Did the victim have to pay a debt to his/her exploiters?",
    debtAmount: "If YES, please specify - Total amount?",
    freedMethod: "How was the victim freed?",
    eventDescription: "Describe what actually happened",
    exploitationType: "Which type(s) of exploitation best describes the victim’s experiences?",
    eProstitution: "Prostitution",
    eOtherSexual: "Other sexual exploitation",
    eOtherSexualOnline: "Sexual exploitation online",
    eOnlinePorno: "Production of online pornographic material",
    eCriminalActivity: "Criminal activity",
    eCriminalActivityType: "If YES, what crime?",
    eForcedLabour: "Forced labour",
    eForcedLabourIndustry: "If YES, what industry?",
    eForcedMarriage: "Forced marriage",
    eVictimKnewSpouse: "Did the victim know the spouse before the wedding?",
    eSpouseNationality: "What is the spouse's nationality?",
    eBPricePaid: "Was a bride price, dowry or dower paid?",
    eBPriceAmountKind: "If YES to bride price, dowry or dower, how much and/or which material good's?",
    eBPriceRecipient: "If YES to bride price, which individual(s) received the bride price?",
    eChildMarriage: "Child marriage",
    eChildMarriageReason: "What was the reason for the child marriage?",
    eVictimPregnancy: "Did the victim fall pregnant as a result of the marriage?",
    eChildFromMarriage: "If YES, how many children were born out of the marriage?",
    eMaternalHealthIssues: "Did the victim have any maternal health problems?",
    eMHealthIssuesDescription: "Describe the health Issue",
    eMarriageViolenceType: "If YES, please specify",
    eMarriageViolence: "During the marriage, was the victim subject to any form of violence?",
    eForcedMilitary: "Forced military",
    eForcedMilitaryType: "Forced military type",
    eArmedGroupName: "What was the name of the armed group? Please specify",
    eVictimMilitaryActivities: "What type of activities did the victim perform?",
    eChildSoldier: "Child soldier",
    eChildSoldierAge: "At what age",
    eOrganRemoved: "Organ removal",
    eBodyPartRemoved: "Which body parts were removed?",
    eOperationLocation: "Where was the operation performed?",
    eOperationCountry: "In which country was the operation performed?",
    eOrganSalePrice: "If organ was sold, how much was offered or actually paid for the removed organ?",
    eOrganPaidTo: "If applicable, to whom was the money paid?",
    eRemarks: "Additional Remarks",
    eRecruitmentType: "If recruitment, how was the victim recruited?",
    eRecruiterRelationship: "What was the victim's relationship to the recruiter?",
    eTraffickingMeans: "What were the means employed in the process of trafficking?",

    exploitationOverview: "Exploitation Overview",
    sexualExploitation: "Sexual Exploitation",
    labourAndCriminalExploitation: "Labour & Criminal Activity Exploitation",
    forcedMarriageExploitation: "Marriage Exploitation",
    millitaryExploitation: "Millitary Exploitation",
    organRemovalExploitation: "Organ Removal Exploitation",
    recruitmentExploitation: "Recruitment Exploitation",
  },
  prosecution: {
    suspectedTraficker: "Suspect",
    caseStatus: "What is the status of the case?",
    trialCourt: "In which trial court is this case enrolled?",
    foreignCourtCountry: "If in  a foreign court, please specify country:",
    courtCaseNumber: "What is the assigned court case number?",
    verdict: "If applicable, what was the verdict:",
    guiltyVerdict: "If GUILTY, please specify:",
    prosecutionOutcome: "What was the outcome of the prosecution?",
    acquittalReason: "If ACQUITTAL, state the reasons:",
    reviewAppealOutcome: "If REVIEW/APPEAL, what was the outcome?",
    penalty: "What was the nature of the sanction/penalty?:",
    yearsImposed: "If CUSTODIAL SANCTION/PENALTY, how many years were imposed?:",
  },
  suspect: {
    firstName: "First name",
    surName: "Surname",
    dob: "Date of birth",
    age: "Age",
    gender: "Sex",
    race: "Race",
    religions: "Religion",
    languages: "Languages",
    citizenship: "Citizenship",
    dualCitizenship: "Country of dual citizenship",
    nationality: "Nationality",
    idType: "ID Type",
    idNumber: "ID Number",
    address: "Address",
    lastPlaceOfResidence: "Last place of residence",
    countryOfBirth: "Country of Birth",
    placeOfBirth: "Place of birth",
  },
  tipAgg: {
    dataSupplier: "What was the nature of the sanction/penalty?",
    totalTip: "Estimated total numbers of TIP assistance cases during the last calendar year",
    totalVictim: "Estimated total numbers of TIP assistance cases during the last calendar year",
    totalFamily: "Total number of confirmed and/or potential victims who received services",
    totalAnon:
      "Total number of anonymous contacts received through a hotline, online chat, through unions or labour inspectors, or through private companies’ value chains",
  },
  arrest: {
    suspectArrested: "Has any suspect been arrested to date?",
    whyNoArrest: "If NO, please explain why there have been no arrests?",
    organizedCrime: "Does the suspect have ties to organized crime?",
    victimSmuggled: "Was the victim travelling with or using the services of migrant smugglers?:",
    howTraffickersOrg: "How is the trafficker(s) organized?",
    investigationDone: "Has an investigation been conducted?",
    whyNoInvestigation: "If NO please specify why?",
    investigationStatus: "If YES, what is the status of the investigation?",
    withdrawnClosedReason: "If WITHDRAWN or CASE FILE CLOSED, please specify?",
    whyPending: "If PENDING, please specify?",
  },
  arrestSom: {
    suspectArrested: "Has any suspect been arrested to date?",
    whyNoArrest: "If NO, please explain why there have been no arrests?",
    organizedCrime: "Does the suspect have ties to organized crime?",
    victimSmuggled: "Was the victim travelling with or using the services of migrant smugglers?:",
    howTraffickersOrg: "How is the smugglers(s) organized?",
    investigationDone: "Has an investigation been conducted?",
    whyNoInvestigation: "If NO please specify why?",
    investigationStatus: "If YES, what is the status of the investigation?",
    withdrawnClosedReason: "If WITHDRAWN or CASE FILE CLOSED, please specify?",
    whyPending: "If PENDING, please specify?",
  },

  assistance: {
    duration: "Duration",
    providedBy: "Provided by",

    socialAssistance: "Social Assistance",

    medicalRehabilitationAssistance: "Medical Rehabilitation Assistance",

    housingAllowance: "Housing Allowance",

    halfwayHouse: "Halfway House",

    shelter: "Shelter",

    vocationalTraining: "Vocational Training",

    incomeGeneratingProject: "Income Generating Project",

    legalAssistance: "Legal assistance",

    medicalAssistance: "Medical assistance",

    financialAssistance: "Financial assistance",

    educationAssistance: "Education assistance",

    immEmmigrationAssistance: "Immigration/Emmigration assistance",

    communityBasedAssistance: "Other community-based assistance",
  },
  yesNo: {
    yes: "Yes",
    no: "No",
  },
  gender: {
    male: "Male",
    female: "Female",
    other: "Other",
  },
  socio: {
    familyStructure: "What was the structure of the family of origin at the time of the trafficking incident?",
    livingWith:
      "If the victim was not living with his/her family prior to entry into the trafficking process, who was he/she living with prior to departure?",
    violence: "Did any violence take place in the social or family setting where the victim was living prior to departure?",
    educationLevel: "What was the last level of education received?",
    lastOccupation: "What was the last activity/occupation the victim was engaged in prior to departure from the place of origin?",
  },
  tipTransit: {
    countryOfOrigin: "Country of Origin or Recruitment",
    countryOfDestination: "Country of Destination or Exploitation",
    countriesOfTransit: "Countries of transit",
    countryOfInterception: "Country of interception",
    meansOfTransportation: "Means of transportation",
    additionalRemarks: "Additional remarks",
    cityVillageOfOrigin: "City/Village of Origin or Recruitment",
    cityVillageOfDest: "Place/Village of Destination or Exploitation",
  },
  somTransit: {
    countryOfOrigin: "Country of origin",
    countryOfDestination: "Country of destination",
    countriesOfTransit: "Countries of transit",
    countryOfInterception: "Country of interception",
    meansOfTransportation: "Means of transportation",
    additionalRemarks: "Additional remarks",
    cityVillageOfOrigin: "City/Village of origin",
    cityVillageOfDest: "City/Village of destination",
  },

  cardDetails: {
    dateCreated: "Created on",
  },
  findHelp: {
    searchString: "Type to search",
  },
  dataValidations: {
    helperTexts: {
      invalidEmail: "Email is Invalid",
      invalidNumber: "Enter a valid number only",
      minNumber: "Minimum value should be",
      maxNumber: "Maximum value should be",
      totalCountError: "Total values exceed the number of victims",

      passwordsMustMatch: "Passwords must match",
      passwordMustContain: "Password must contain",
      passwordALowerCase: "at least one lowercase character",
      passwordAUpperCase: "at least one uppercase character",
      passwordADigit: "at least one digit",
      passwordASpecialCharacter: "at least one special character",
      passwordMinLength: "at least 8 characters",
      resetTokenInvalid: "Enter a valid reset token",
    },
    otherField: "Other",
    enterOtherField: "Specify other",
    pleaseWait: "Please Wait",
    checkFormErrors: "Check form errors first",
    solveCaptcha: "Solve Captcha First",
  },
  notifications: {
    noSearchResultsFindHelp: "No Search results found",
    uploadSuccess: "Data Saved Successfully",
    uploadAllSuccess: "All Forms Uploaded Successfully",
    uploadError: "Error uploading form",
    fillPreviousForms: "Fill Previous Forms first",
    updateSuccess: "App data updated successfully",
    offlineHeader: "Offline",
    offlineText: "You are not connected to the internet.",
    successHeader: "Success",
    pendingFormsExist: "Forms pending to be uploaded.  Connect to internet and click upload",
    noPendingForms: "You have no pending forms to upload",
    uploadingForms: "Uploading Forms",
    appDataUpdatedSuccess: "App data updated successfully",
    loginFailHeader: "Login Failed",
    loginFailMessage: "Please check your credentials and try again.",
    noSearchResults: "No search results to display. Use the Advanced Search to find victims.",
    pleaseCreateANewCase: "Please create a new case",
  },
  selectList: {
    placeholder: "Select Option",
    searchPlaceholder: "Search",
    selectedTextPlaceholder: "Selected",
    notFoundText: "No data found",
  },
  datePicker: {
    placeholder: "Select date",
    label: "Date",
    confirmLabel: "Confirm",
    cancelLabel: "Cancel",
  },
};

export default en;
