import React, { useContext, useEffect, useState } from 'react';
import MyLoadingBar from '../components/MyLoadingBar';
import MyDashboardCard from '../components/MyDashboardCard';
import { Box, Container, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { AuthContext } from '../App';
import PeopleIcon from '@mui/icons-material/People';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { PieChart } from '@mui/x-charts/PieChart';
import { fetchDashboardStats } from '../api/api';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ChartContainer, BarPlot } from '@mui/x-charts';
import { styled, useTheme } from '@mui/system';
import MyCard from '../components/MyInputs/MyCard';
import MyCardTitle from '../components/MyInputs/MyCardTitle';

const DashboardScreen = () => {
  const { strings, setVictimId, language } = useContext(AuthContext);
  const [refreshing, setRefreshing] = useState(false);
  const [stats, setStats] = useState();
  const [tickPlacement, setTickPlacement] = React.useState('middle');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dashboardCards = [
    { id: 1, title: strings.dashboard.victims, count: stats?.v_count, icon: <PeopleIcon /> },
    { id: 2, title: strings.dashboard.suspects, count: stats?.s_count, icon: <DirectionsWalkIcon /> },
    { id: 3, title: strings.dashboard.prosecutions, count: stats?.prosecuted_counts?.total_prosecuted, icon: <GavelIcon /> },
    { id: 4, title: strings.dashboard.convictions, count: stats?.prosecuted_counts?.total_convicted, icon: <AccountBalanceIcon /> },
  ];

  const fetchStats = async () => {
    try {
      setRefreshing(true);

      const dataFromAPI = await fetchDashboardStats(language);
      console.log(dataFromAPI);
      

      dataFromAPI['genders'] =
        dataFromAPI.gender_counts.map((item) => {
          return {
            label: item.gender__name_en || item.gender__name_fr || item.gender__name_pt,
            value: item.count,
          }
        }
        )

      dataFromAPI['suspects_prosecutions'] = [
        { label: 'Prosecutions', value: dataFromAPI.prosecuted_counts.total_prosecuted },
        { label: 'Convictions', value: dataFromAPI.prosecuted_counts.total_convicted },
      ]


      dataFromAPI['suspects_genders'] =
        dataFromAPI.suspects_gender_counts.map((item) => {
          return {
            label: item.gender__name_en || item.gender__name_pt || item.gender__name_fr,
            value: item.count,
          }
        }
        )

      dataFromAPI['gender_nationalities'] =
        dataFromAPI.nationality_counts.map((item) => {
          return {
            label: item.citizenship__name,
            value: item.count,
          }
        }
        )

      dataFromAPI['role_in_trafficking'] =
        dataFromAPI.role_counts.map((item) => {
          return {
            label: item.role_in_trafficking__name_en || item.role_in_trafficking__name_pt || item.role_in_trafficking__name_fr,
            value: item.count,
          }
        }
        )


      dataFromAPI['exploitations'] =
        Object.entries(dataFromAPI.exploitation_counts).map(([key, value]) => {
          return {
            label: key,
            value: value,
          };
        });





      setStats(dataFromAPI);
    } catch (error) {
      console.log(error);
      // const offlineData = await retrieveData("victims");
      // if (offlineData) {
      //   setVictims(offlineData);
      //   setRefreshing(false);
      // }
    } finally {
      setRefreshing(false);
    }
  };
  useEffect(() => {
    fetchStats();
  }, []);


  return (
    <Container sx={{ mt: 8 }}>
      <MyLoadingBar loading={refreshing} />
      <Grid container spacing={2}>
        {dashboardCards.map(card => (
          <Grid item xs={12} sm={6} lg={3} mb={4} key={card.id}>
            <MyDashboardCard
              title={card.title}
              count={card.count}
              Icon={card.icon}
            />
          </Grid>
        ))}
      </Grid>

      {stats ?
        (
          <>
            <MyCard>
              <MyCardTitle title={strings.dashboard.victimDemographics} />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} mb={4} >
                  <Box>
                    <Typography>{strings.dashboard.byGender}</Typography>
                    <PieChart
                      series={[
                        {
                          data: stats.genders,
                        },
                      ]}
                      height={200}
                    />
                  </Box>

                </Grid>
                <Grid item sm={6} mb={4} >
                  <Box>
                    <Typography>{strings.dashboard.byNationality}</Typography>
                    <BarChart
                      dataset={stats.gender_nationalities}
                      xAxis={[{ dataKey: 'label', scaleType: 'band', tickPlacement }]}
                      series={[{ dataKey: 'value' }]}
                      height={500}
                    />
                  </Box>
                </Grid>

              </Grid>
            </MyCard>

            <MyCard>

              {/* <Divider sx={{ mt: 4 }} /> */}
              <MyCardTitle title={strings.dashboard.exploitationAnalysis} />

              <BarChart
                dataset={stats.exploitations}
                xAxis={[{ dataKey: 'label', scaleType: 'band', tickPlacement }]}
                series={[{ dataKey: 'value' }]}
                height={500}
              />
            </MyCard>
            <MyCard>
              <MyCardTitle title={strings.dashboard.suspectDemographics} />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} mb={4} >
                  <Box>
                    <Typography>{strings.dashboard.byGender}</Typography>
                    <PieChart
                      series={[
                        {
                          data: stats.suspects_genders,
                        },
                      ]}
                      height={200}
                    />
                  </Box>

                </Grid>
                <Grid item sm={6} mb={4} >
                  <Box>
                    <Typography>{strings.dashboard.byNationality}</Typography>
                    <BarChart
                      dataset={stats.role_in_trafficking}
                      xAxis={[{ dataKey: 'label', scaleType: 'band', tickPlacement }]}
                      series={[{ dataKey: 'value' }]}
                      height={500}
                    />
                  </Box>
                </Grid>

              </Grid>
            </MyCard>

            <MyCard>
              <MyCardTitle title={strings.dashboard.suspectProsecutionAndConviction} />
              <BarChart
                dataset={stats.suspects_prosecutions}
                xAxis={[{ dataKey: 'label', scaleType: 'band', tickPlacement }]}
                series={[{ dataKey: 'value' }]}
                height={500}
              />
            </MyCard>

          </>

        )

        : null
      }
    </Container>
  );
};

export default DashboardScreen;
