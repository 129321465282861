import { addVictim } from "../api/api";

const fr = {
  app: {
    app_language: "Langue",
    login: "Se connecter",
    saveAndProceed: "Enregistrer et continuer",
    saveAndClose: "Sauver et fermer",
    cancel: "Annuler",
    view: "voir",
    modify: "modifier",
    submit: "Soumettre",
    updateDbLookups: "Mettre à jour l'application",
    search: "Recherche",
  },
  appMenu: {
    tip: "Traite des personnes",
    som: "Trafic de migrants",
    aggregates: "Données TIP agrégées",
    offlineSync: "Télécharger",
    findHelp: "Trouver de l'aide",
    settings: "paramètres",
    dashboard: "Tableau de Bord",
    search: "Rechercher",
    profile: "Profil",
  },
  dashboard: {
    victims: "Victimes",
    suspects: "Suspects",
    prosecutions: "Poursuites",
    convictions: "Condamnations",
    victimDemographics: "Démographie des victimes",
    exploitationAnalysis: "Analyse de l'exploitation",
    suspectDemographics: "Démographie des suspects",
    suspectProsecutionAndConviction: "Suspect, poursuite et condamnation",
    byGender: "Par genre",
    byNationality: "Par nationalité",
  },
  authMenu: {
    login: "TIP-SOM",
  },
  tipMenu: {
    victims: "Victimes",
    suspects: "Suspects",
    consent: "Consentement",
    addVictim: "Formulaire de victime",
    viewVictim: "Détails sur la victime",
  },
  somMenu: {
    victims: "Victimes",
    suspects: "Suspects",
    consent: "Consentement",
    addVictim: "Formulaire de cas",
    viewVictim: "Détails du cas",
  },
  login: {
    title: "Se connecter",
    username: "Nom d'utilisateur",
    email: "Adresse électronique",
    password: "Mot de passe",
    selectOption: "Sélectionner une option",
    register: "S'inscrire",
    forgotPassword: "Mot de passe oublié ?",
    registrationSuccess: "Succès",
    registrationSuccessDescription: "Compte enregistré avec succès \nVeuillez procéder à la connexion",
  },
  logout: {
    logoutTitle: "Se déconnecter",
    logoutText: "Êtes-vous sûr de vouloir vous déconnecter?",
    logoutButton: "Se déconnecter",
  },
  victimScreen: {
    victimsTitle: "Victimes",
    suspectsTitle: "Suspects",
  },
  victimCard: {
    exploitationForm: "Données d'exploitation",
    transitForm: "Données de transit",
    investigationForm: "Données d'enquête",
    suspectForm: "Données suspects",
    prosecutionForm: "Données de poursuite",
    assistanceForm: "Données d'assistance",
    socioForm: "Données socio-économiques",
  },
  victimDetails: {
    caseInterview: "Entretien de cas",
  },
  addTip: {
    victimProfile: "Profil de la victime",
    exploitation: "Trafic d'êtres humains",
    transitRoute: "Itinéraire de transit",
    investigations: "Enquêtes",
    suspects: "Trafiquant présumé",
    suspect: "Suspect",
    prosecutions: "Poursuites",
    assistance: "Types d'assistance",
    socio: "Socio-économique avant la traite",
    aggregate: "Données agrégées sur l’assistance",
    case: "Données de cas",
    addNewVictim: "Ajouter une Victime de TIP",
    addNewCase: "Ajouter un Cas TIP",
  },
  addSom: {
    victimProfile: "Profil de la victime",
    case: "Données de cas",
    transitRoute: "Itinéraire de transit",
    investigations: "Enquêtes",
    suspects: "Passeur Suspecté",
    prosecutions: "Poursuites",
    assistance: "Types d'assistance",
    socio: "Socio-économique avant la traite",
    aggregate: "Données agrégées sur l’assistance",
    addNewVictim: "Ajouter une Victime SOM",
    addNewCase: "Ajouter un Cas de SOM",
  },
  consent: {
    shareData:
      "La victime a-t-elle été informée que (nom de l'organisation/du service) se réserve le droit de partager les données individuelles la concernant à des fins d'assistance et uniquement avec les services gouvernementaux et les organisations partenaires impliqués dans l'assistance directe?",
    limitedDisclosure:
      "La victime a-t-elle également été informée que (nom de l'organisation/du service) se réserve le droit de divulguer aux forces de l’ordre des données non personnelles basées sur les informations recueillies lors de l'entretien, dans le but de secourir d'autres victimes qui restent sous le contrôle des trafiquants ou d'empêcher d'autres victimes potentielles d'être victimes de la traite des êtres humains?",
    anonymousDataUse:
      "La victime a-t-elle également été informée que (nom de l'organisation/service) se réserve le droit d'utiliser des données (uniquement anonymes et agrégées) à des fins de recherche?",
    rightToRefuse: "La victime a le droit de refuser de répondre ou de s'abstenir en répondant Non applicable (NA) ou Ne sait pas (NSP)?",
    minorOrImpairment: "La victime est-elle mineure ou une personne ayant des déficiences physiques ou cognitives?",
    dataCollectorNoteTitle: "NOTE AU COLLECTEUR DE DONNÉES",
    dataCollectorNote1:
      "1. Les données sur les migrants de contrebande impliquent plusieurs victimes par rapport aux victimes de la traite, donc la saisie des données permet des données collectives (saisie des données de victimes SOM uniques et multiples)",
    dataCollectorNote2: "2. Les données seront agrégées en ensembles de 10 ans",
  },
  profileForm: {
    firstName: "Prénom",
    familyName: "Nom de famille",
    position: "Poste",
    organization: "Autorité/Organisation",
    address: "Adresse physique",
    email: "Adresse électronique",
    country: "Pays",
    dataEntryPurpose: "Objectif de saisie des données",
    password: "Mot de passe",
    confirmPassword: "Confirmer le mot de passe",
    leavePasswordBlank: "Laisser vide pour conserver le mot de passe actuel",
    interviewerInformation: "Informations sur l'intervieweur",
    interviewerRegistration: "Inscription de l'Intervieweur",
  },

  decisionPopup: {
    singleVictim: "Victime unique",
    multipleVictim: "Plusieurs victimes",
  },
  victim: {
    initials: "Initiales",
    email: "Adresse électronique",
    age: "Âge",
    dob: "Date de naissance",
    gender: "Sexe",
    race: "Race",
    religions: "Religion",
    citizenship: "Citoyenneté",
    dualCitizenship: "pays de double nationalité",
    languages: "Langues",
    idType: "Type d'identification",
    idNumber: "Numéro d'identification",
    address: "Adresse",
    lastPlaceOfResidence: "Dernier lieu de résidence",
    countryOfBirth: "Pays de naissance",
    placeOfBirth: "Lieu de naissance",
    interviewDate: "Date de l'entretien",
    interviewerCountry: "Pays de l’enquêteur",
    interviewerLocation: "Emplacement de l'intervieweur",
    additionalRemarks: "Remarque additionnelle",
    dateStart: "Date de début de l'entretien",
    dateEnd: "Date de fin de l'entretien",
  },
  victimBulk: {
    numberOfVictims: "Combien y a-t-il de victimes ?",
    genders: "Quelle est leur répartition par sexe ?",
    ages: "Quelle est la répartition par âge des victimes ?",
    races: "Quelles races sont les victimes ?",
    religions: "Religion",
    languages: "Quelles langues parlent-ils ?",
    countries: "Pays d'origine suspecté(s)",
    interviewDate: "Date de l'entretien",
    interviewerCountry: "Pays de l'enquêteur",
    interviewerLocation: "Emplacement de l'intervieweur",
    additionalRemarks: "Remarque additionnelle",
  },
  caseData: {
    dateOfArrest: "Date d'arrestation",
    roleInTrafficking: "Rôle dans le trafic",
    countryFrom: "Pays de",
    placeFrom: "Lieu à partir de",
    countryTo: "Pays vers",
    placeTo: "Lieu à",
    caseNumber: "Numéro de dossier",
    additionalRemarks: "Remarque additionnelle",
  },
  exploitation: {
    subjectToExploitation: "La victime a-t-elle été exploitée?",
    intentToExploit: "Si NON, y avait-il une intention d'exploiter?",
    exploitationLength: "Si OUI, pendant combien de temps la victime a-t-elle été exploitée ?",
    exploitationAge: "Si OUI, quel était l’âge de la victime pendant la période d’exploitation ?",
    paidDebt: "La victime a-t-elle dû payer une dette envers ses exploiteurs ?",
    debtAmount: "Si OUI, veuillez préciser - Montant total ?",
    freedMethod: "Comment la victime a-t-elle été libérée ?",
    eventDescription: "Décrivez ce qui s'est réellement passé",
    exploitationType: "Quel(s) type(s) d’exploitation décrit le mieux les expériences de la victime ?",
    eProstitution: "La prostitution",
    eOtherSexual: "Autre exploitation sexuelle",
    eOtherSexualOnline: "Exploitation sexuelle en ligne",
    eOnlinePorno: "Production de matériel pornographique en ligne",
    eCriminalActivity: "Activité criminelle",
    eCriminalActivityType: "Si OUI, quel crime?",
    eForcedLabour: "Travail forcé",
    eForcedLabourIndustry: "Si OUI, quelle industrie ?",
    eForcedMarriage: "Mariage forcé",
    eVictimKnewSpouse: "La victime connaissait-elle le conjoint avant le mariage ?",
    eSpouseNationality: "Quelle est la nationalité du conjoint ?",
    eBPricePaid: "Une dot, une dot ou une dot ont-elles été versées ?",
    eBPriceAmountKind: "Si OUI au prix de la mariée, à la dot ou au douaire, de quel montant et/ou de quel bien matériel s'agit-il ?",
    eBPriceRecipient: "Si OUI à la dot, quelle(s) personne(s) a reçu la dot ?",
    eChildMarriage: "Le mariage d'enfants",
    eChildMarriageReason: "Quelle était la raison du mariage des enfants ?",
    eVictimPregnancy: "La victime est-elle tombée enceinte à la suite du mariage ?",
    eChildFromMarriage: "Si OUI, combien d’enfants sont nés hors mariage ?",
    eMaternalHealthIssues: "La victime avait-elle des problèmes de santé maternelle ?",
    eMHealthIssuesDescription: "Décrivez le problème de santé",
    eMarriageViolenceType: "Si OUI, veuillez préciser",
    eMarriageViolence: "Durant le mariage, la victime a-t-elle subi une quelconque forme de violence ?",
    eForcedMilitary: "Militaire forcé",
    eForcedMilitaryType: "Type militaire forcé",
    eArmedGroupName: "Quel était le nom du groupe armé ? Veuillez préciser",
    eVictimMilitaryActivities: "Quel type d'activités la victime effectuait-elle ?",
    eChildSoldier: "Enfant soldat",
    eChildSoldierAge: "À quel âge",
    eOrganRemoved: "Prélèvement d'organes",
    eBodyPartRemoved: "Quelles parties du corps ont été enlevées ?",
    eOperationLocation: "Où l'opération a-t-elle été réalisée ?",
    eOperationCountry: "Dans quel pays l’opération a-t-elle été réalisée ?",
    eOrganSalePrice: "Si l’organe a été vendu, combien a été offert ou réellement payé pour l’organe prélevé ?",
    eOrganPaidTo: "Le cas échéant, à qui l’argent a-t-il été versé ?",
    eRemarks: "Remarque additionnelle",
    eRecruitmentType: "En cas de recrutement, comment la victime a-t-elle été recrutée ?",
    eRecruiterRelationship: "Quelle était la relation de la victime avec le recruteur?",
    eTraffickingMeans: "Quels ont été les moyens utilisés dans le processus de trafic ?",
  },
  prosecution: {
    suspectedTraficker: "Suspect",
    caseStatus: "Quel est l’état du dossier ?",
    trialCourt: "Devant quel tribunal de première instance cette affaire est-elle inscrite ?",
    foreignCourtCountry: "Si vous êtes devant un tribunal étranger, veuillez préciser le pays :",
    courtCaseNumber: "Quel est le numéro de dossier attribué au tribunal ?",
    verdict: "Le cas échéant, quel a été le verdict :",
    guiltyVerdict: "Si COUPABLE, veuillez préciser :",
    prosecutionOutcome: "Quelle a été l’issue des poursuites ?",
    acquittalReason: "En cas d’ACQUITTEMENT, indiquez les raisons :",
    reviewAppealOutcome: "Si EXAMEN/APPEL, quel a été le résultat ?",
    penalty: "Si EXAMEN/APPEL, quel a été le résultat ?",
    yearsImposed: "En cas de SANCTION/PÉNALITÉ DE PLACEMENT, combien d'années ont été imposées ?",
  },
  suspect: {
    firstName: "Prénom",
    surName: "Nom de famille",
    dob: "Date de naissance",
    age: "Âge",
    gender: "Sexe",
    race: "Course",
    religions: "Religion",
    languages: "Langues",
    citizenship: "Citoyenneté",
    dualCitizenship: "pays de double nationalité",
    nationality: "Nationalité",
    idType: "Type d'identification",
    idNumber: "Numéro d'identification",
    address: "Adresse",
    lastPlaceOfResidence: "Dernier lieu de résidence",
    countryOfBirth: "Pays de naissance",
    placeOfBirth: "Lieu de naissance",
  },
  tipAgg: {
    dataSupplier: "Quelle était la nature de la sanction/pénalité ?",
    totalTip: "Nombre total estimé de cas d’assistance pour la TIP au cours de la dernière année civile",
    totalVictim: "Nombre total estimé de cas d’assistance pour la TIP au cours de la dernière année civile",
    totalFamily: "Nombre total de victimes confirmées et/ou potentielles qui ont reçu des services",
    totalAnon:
      "Nombre total de contacts anonymes reçus via une hotline, un chat en ligne, via des syndicats ou des inspecteurs du travail, ou via les chaînes de valeur d'entreprises privées",
  },
  arrest: {
    suspectArrested: "Un suspect a-t-il été arrêté à ce jour ?",
    whyNoArrest: "Si NON, veuillez expliquer pourquoi il n’y a eu aucune arrestation ?",
    organizedCrime: "Le suspect a-t-il des liens avec le crime organisé ?",
    victimSmuggled: "La victime voyageait-elle avec ou utilisait-elle les services de passeurs de migrants ?",
    howTraffickersOrg: "Comment le(s) trafiquant(s) sont-ils organisés ?",
    investigationDone: "Une enquête a-t-elle été menée ?",
    whyNoInvestigation: "Si NON, veuillez préciser pourquoi ?",
    investigationStatus: "Si OUI, quel est l’état d’avancement de l’enquête ?",
    withdrawnClosedReason: "En cas de RETRAIT ou de DOSSIER CLÔTÉ, veuillez préciser ?",
    whyPending: "Si EN ATTENTE, veuillez préciser ?",
  },
  arrestSom: {
    suspectArrested: "Un suspect a-t-il été arrêté à ce jour ?",
    whyNoArrest: "Si NON, veuillez expliquer pourquoi il n’y a eu aucune arrestation ?",
    organizedCrime: "Le suspect a-t-il des liens avec le crime organisé ?",
    victimSmuggled: "La victime voyageait-elle avec ou utilisait-elle les services de passeurs de migrants ?",
    howTraffickersOrg: "Comment le(s) passeurs(s) sont-ils organisés ?",
    investigationDone: "Une enquête a-t-elle été menée ?",
    whyNoInvestigation: "Si NON, veuillez préciser pourquoi ?",
    investigationStatus: "Si OUI, quel est l’état d’avancement de l’enquête ?",
    withdrawnClosedReason: "En cas de RETRAIT ou de DOSSIER CLÔTÉ, veuillez préciser ?",
    whyPending: "Si EN ATTENTE, veuillez préciser ?",
  },
  assistance: {
    duration: "Durée",
    providedBy: "Fourni par",

    socialAssistance: "Assistance sociale",

    medicalRehabilitationAssistance: "Aide à la réadaptation médicale",

    housingAllowance: "Allocation de logemente",

    halfwayHouse: "Maison de transition",

    shelter: "Abri",

    vocationalTraining: "Formation professionnelle",

    incomeGeneratingProject: "Projet générateur de revenus",

    legalAssistance: "Assistance légale",

    medicalAssistance: "Assistance médicale",

    financialAssistance: "Aide financière",

    educationAssistance: "Aide à l'éducation",

    immEmmigrationAssistance: "Aide à l'immigration/à l'émigration",

    communityBasedAssistance: "Autres aides communautaires",
  },
  yesNo: {
    yes: "Oui",
    no: "Non",
  },
  gender: {
    male: "mâle",
    female: "femelle",
    other: "autre",
  },
  socio: {
    familyStructure: "Quelle était la structure de la famille d’origine au moment de l’incident de traite ?",
    livingWith:
      "Si la victime ne vivait pas avec sa famille avant d'entrer dans le processus de traite, avec qui vivait-elle avant son départ?",
    violence: "Des violences ont-elles eu lieu dans le cadre social ou familial où vivait la victime avant son départ?",
    educationLevel: "Quel a été le dernier niveau d'éducation reçu?",
    lastOccupation: "Quelle était la dernière activité/occupation exercée par la victime avant son départ du lieu d'origine?",
  },
  tipTransit: {
    countryOfOrigin: "Pays d'Origine ou de Recrutement",
    countryOfDestination: "Pays de Destination ou d'Exploitation",
    countriesOfTransit: "Pays de Transit",
    countryOfInterception: "Pays d'Interception",
    meansOfTransportation: "Moyens de Transport",
    additionalRemarks: "Remarques Supplémentaires",
    cityVillageOfOrigin: "Ville/Village d'Origine ou de Recrutement",
    cityVillageOfDest: "Ville/Village de Destination ou d'Exploitation",
  },
  somTransit: {
    countryOfOrigin: "Pays d'origine",
    countryOfDestination: "Pays de destination",
    countriesOfTransit: "Pays de transit",
    countryOfInterception: "Pays d'interception",
    meansOfTransportation: "Moyens de transport",
    additionalRemarks: "Remarque additionnelle",
    cityVillageOfOrigin: "Ville/Village d'origine",
    cityVillageOfDest: "Ville / village de destination",
  },
  cardDetails: {
    dateCreated: "Créé sur",
  },
  findHelp: {
    searchString: "Tapez pour rechercher",
  },
  dataValidations: {
    helperTexts: {
      invalidEmail: "Email invalide",
      invalidNumber: "Entrez uniquement un nombre",
      minNumber: "La valeur minimale doit être",
      maxNumber: "La valeur maximale doit être",
      totalCountError: "Le total des valeurs dépasse le nombre de victimes",

      passwordsMustMatch: "Les mots de passe doivent correspondre",
      passwordMustContain: "Le mot de passe doit contenir",
      passwordALowerCase: "au moins un caractère en minuscule",
      passwordAUpperCase: "au moins un caractère en majuscule",
      passwordADigit: "au moins un chiffre",
      passwordASpecialCharacter: "au moins un caractère spécial",
      passwordMinLength: "au moins 8 caractères",
      resetTokenInvalid: "Entrez un jeton de réinitialisation valide",
    },
    otherField: "Autre",
    enterOtherField: "Précisez autre",
    pleaseWait: "S'il vous plaît, attendez",
    checkFormErrors: "Vérifiez d'abord les erreurs du formulaire",
    solveCaptcha: "Résolvez d'abord le Captcha",
  },
  notifications: {
    noSearchResultsFindHelp: "Aucun résultat de recherche trouvé",
    uploadSuccess: "Données enregistrées avec succès",
    uploadAllSuccess: "Tous les formulaires ont été téléchargés avec succès",
    uploadError: "Erreur lors du téléchargement du formulaire",
    fillPreviousForms: "Remplissez d'abord les formulaires précédents",
    updateSuccess: "Données de l'application mises à jour avec succès",
    offlineHeader: "Hors ligne",
    offlineText: "Vous n'êtes pas connecté à Internet.",
    successHeader: "Succès",
    pendingFormsExist: "Formulaires en attente de téléchargement. Connectez-vous à Internet et cliquez sur télécharger",
    noPendingForms: "Vous n'avez aucun formulaire en attente de téléchargement",
    uploadingForms: "Téléchargement des formulaires",
    appDataUpdatedSuccess: "Données de l'application mises à jour avec succès",
    loginFailHeader: "Échec de la connexion",
    loginFailMessage: "Nom d'utilisateur ou mot de passe incorrect",
    noSearchResults: "Aucun résultat de recherche trouvé",
    pleaseCreateANewCase: "Veuillez créer un nouveau cas",
  },
  selectList: {
    placeholder: "Sélectionner une option",
    searchPlaceholder: "Rechercher",
    selectedTextPlaceholder: "Sélectionné",
    notFoundText: "Aucune donnée trouvée",
  },
  datePicker: {
    placeholder: "Sélectionner une date",
    label: "Date",
    confirmLabel: "Confirmer",
    cancelLabel: "Annuler",
  },
};

export default fr;
